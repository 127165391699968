import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeartIcon, SearchIcon } from '@heroicons/react/solid';
import { isTokenValid } from './utils/auth';

function Mp3() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tracks, setTracks] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Memoize fetchTracks using useCallback
  const fetchTracks = useCallback(
    async (query = '') => {
      if (!isTokenValid(token)) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(
          `https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/list-mp3?query=${encodeURIComponent(query)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setTracks(data);
      } catch (error) {
        console.error('Error fetching tracks:', error);
      }
    },
    [token, navigate]
  );

  // Fetch all files when the component mounts
  useEffect(() => {
    fetchTracks();
  }, [fetchTracks]);

  // Handle search
  const handleSearch = async () => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    if (searchTerm.trim() === '') {
      fetchTracks();
    } else {
      fetchTracks(searchTerm);
    }
  };

  // Handle adding/removing from favorites
  const toggleFavorite = (track) => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    if (favorites.includes(track)) {
      setFavorites(favorites.filter((fav) => fav !== track)); // Remove from favorites
    } else {
      setFavorites([...favorites, track]); // Add to favorites
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Header Section */}
      <div className="w-full max-w-4xl flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">MP3 Player</h1>
        <button
          className="flex items-center bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
          onClick={() => {
            if (isTokenValid(token)) {
              navigate('/favorites');
            } else {
              navigate('/login'); // Redirect to login if token is invalid
            }
          }}
        >
          <HeartIcon className="w-5 h-5 mr-2" />
          Favorite Tracks
        </button>
      </div>

      {/* Search Section */}
      <div className="w-full max-w-4xl flex items-center space-x-4 mb-6">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          placeholder="Search for a track..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
        >
          <SearchIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Tracks List */}
      <div className="w-full max-w-4xl">
        {tracks.length > 0 ? (
          tracks.map((track, index) => (
            <div key={index} className="flex flex-col mb-4">
              <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-md">
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      if (isTokenValid(token)) {
                        setCurrentTrack(track);
                      } else {
                        navigate('/login'); // Redirect to login if token is invalid
                      }
                    }}
                    className="text-indigo-500 hover:text-indigo-700"
                  >
                    Play
                  </button>
                  <span className="ml-4">{track}</span>
                </div>
                <button onClick={() => toggleFavorite(track)}>
                  <HeartIcon
                    className={`w-6 h-6 ${favorites.includes(track) ? 'text-red-500' : 'text-gray-400'}`}
                  />
                </button>
              </div>

              {/* Audio Player directly below the selected track */}
              {currentTrack === track && (
                <div className="bg-white p-4 mt-2 rounded-lg shadow-md">
                  <h2 className="text-xl mb-4">{currentTrack}</h2>
                  <audio
                    controls
                    src={`https://d2grd7lksa1n4b.cloudfront.net/mp3/${currentTrack}`} // Replace with the actual S3 URL or pre-signed URL
                    className="w-full"
                    controlsList="nodownload" // Prevent downloading
                  >
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No tracks found.</p>
        )}
      </div>
    </div>
  );
}

export default Mp3;
