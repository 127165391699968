import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/solid'; // Icon for favorite
import { isTokenValid } from './utils/auth';
import ImageViewer from './ImageViewer'; // Import the ImageViewer component

// Function to fetch username from the token
const fetchUsername = async () => {
  const token = localStorage.getItem('token'); // Get the JWT token from localStorage

  try {
    const response = await fetch('https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/fetch-username', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data.username; // Return the username from the response
  } catch (error) {
    console.error('Error fetching username:', error);
    throw new Error('Unable to fetch username');
  }
};

function Pictures() {
  const [pictures, setPictures] = useState([]);  
  const [albums, setAlbums] = useState([]);      
  const [currentAlbum, setCurrentAlbum] = useState(''); 
  const [favorites, setFavorites] = useState([]); 
  const [newAlbumName, setNewAlbumName] = useState('');
  const [albumModal, setAlbumModal] = useState(false); 
  const [username, setUsername] = useState(''); 
  const [isViewerOpen, setIsViewerOpen] = useState(false); 
  const [currentImage, setCurrentImage] = useState(null);  
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (isTokenValid(token)) {
      fetchUsername().then((username) => {
        setUsername(username); 
        fetchPictures(username); 
      });
    } else {
      navigate('/login');
    }
  }, [token, navigate]);

  const fetchPictures = async (username, albumId = '') => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(
        `https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/list-pics?albumId=${encodeURIComponent(albumId)}&username=${encodeURIComponent(username)}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setPictures(data.pictures || []); 
      setAlbums(data.albums || []);     
    } catch (error) {
      console.error('Error fetching pictures:', error);
    }
  };

  const createAlbum = async () => {
    try {
      const response = await fetch('https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/create-album', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          albumName: newAlbumName,
          username,
        }),
      });

      if (response.ok) {
        fetchPictures(username); 
        setAlbumModal(false); 
      }
    } catch (error) {
      console.error('Error creating album:', error);
    }
  };

  const toggleFavorite = async (picture) => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    if (favorites.includes(picture)) {
      setFavorites(favorites.filter((fav) => fav !== picture)); 
    } else {
      setFavorites([...favorites, picture]); 
    }

    try {
      await fetch('https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/toggle-favorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          pictureId: picture.pictureId,
          username,
        }),
      });
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const moveToAlbum = async (pictureId, albumId) => {
    try {
      await fetch(`https://vtbqw4quej.execute-api.eu-west-1.amazonaws.com/testing/move-picture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          pictureId,
          albumId,
          username,
        }),
      });
      fetchPictures(username); 
    } catch (error) {
      console.error('Error moving picture:', error);
    }
  };

  const openImageViewer = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsViewerOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Your Pictures</h1>
        <button
          className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
          onClick={() => setAlbumModal(true)} 
        >
          Create Album
        </button>
      </div>

      {albums.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl mb-4">Albums</h2>
          <div className="flex space-x-4">
            {albums.map((album) => (
              <button
                key={album.albumId}
                onClick={() => setCurrentAlbum(album.albumId)}
                className="bg-white p-4 rounded-lg shadow-md"
              >
                {album.albumName}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
        {pictures.length > 0 ? (
          pictures.map((picture, index) => (
            <div
              key={index}
              className="relative group bg-white p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openImageViewer(picture.pictureUrl)} // Open image viewer on card click
            >
              <img
                src={picture.pictureUrl}
                alt={`Picture ${index + 1}`}
                className="w-full h-48 object-cover rounded-lg"
              />

              <div className="absolute top-2 right-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent opening viewer on favorite button click
                    toggleFavorite(picture);
                  }}
                  className="bg-white p-2 rounded-full shadow-lg"
                >
                  <HeartIcon
                    className={`w-6 h-6 ${
                      favorites.includes(picture) ? 'text-red-500' : 'text-gray-400'
                    }`}
                  />
                </button>
              </div>

              <div className="mt-4">
                <label htmlFor={`album-select-${index}`} className="block mb-2">
                  Move to Album:
                </label>
                <select
                  id={`album-select-${index}`}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  onChange={(e) => moveToAlbum(picture.pictureId, e.target.value)}
                >
                  <option value="">Select Album</option>
                  {albums.map((album) => (
                    <option key={album.albumId} value={album.albumId}>
                      {album.albumName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))
        ) : (
          <p>No pictures found.</p>
        )}
      </div>

      {albumModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4">Create New Album</h2>
            <input
              type="text"
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
              placeholder="Album Name"
              value={newAlbumName}
              onChange={(e) => setNewAlbumName(e.target.value)}
            />
            <button
              onClick={createAlbum}
              className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
            >
              Create
            </button>
          </div>
        </div>
      )}

      {isViewerOpen && (
        <ImageViewer
          imageUrl={currentImage}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </div>
  );
}

export default Pictures;
